import React from 'react'
import Img1 from '../Event_Fotos/img1.JPG'
import Img2 from '../Event_Fotos/img2.JPG'
import Img3 from '../Event_Fotos/img3.jpg'
import Img4 from '../Event_Fotos/img4.JPG'
import Img5 from '../Event_Fotos/img5.JPG'
import Img6 from '../Event_Fotos/img6.JPG'
import Img7 from '../Event_Fotos/img7.JPG'
import Img8 from '../Event_Fotos/IMG_6309.jpg'
import Img9 from '../Event_Fotos/img12.jpg'


import nc1 from '../Event_Fotos/netcrypt1.JPG'
import nc2 from '../Event_Fotos/netcrypt2.JPG'
import nc3 from '../Event_Fotos/netcrypt3.JPG'
import nc4 from '../Event_Fotos/netcrypt4.JPG'
import nc5 from '../Event_Fotos/netcrypt5.JPG'
import nc6 from '../Event_Fotos/netcrypt6.JPG'
import nc7 from '../Event_Fotos/netcrypt7.jpg'
import nc8 from '../Event_Fotos/netcrypt8.jpg'
import nc9 from '../Event_Fotos/netcrypt9.JPG'


import icict1 from '../Event_Fotos/ICICT1.JPG'
import icict2 from '../Event_Fotos/ICICT2.JPG'
import icict3 from '../Event_Fotos/ICICT3.JPG'
import icict4 from '../Event_Fotos/ICICT4.JPG'
import icict5 from '../Event_Fotos/ICICT5.JPG'
import icict6 from '../Event_Fotos/ICICT6.JPG'
import icict7 from '../Event_Fotos/ICICT7.JPG'
import icict8 from '../Event_Fotos/ICICT8.JPG'
import icict9 from '../Event_Fotos/ICICT9.JPG'


import talk1 from '../Event_Fotos/gen_talk1.jpg'
import talk2 from '../Event_Fotos/gen_talk2.jpg'
import talk3 from '../Event_Fotos/gen_talk3.jpg'




function Recentevents() {

  return (
    <div className="container my-5">

        <div className="container">
        <h2 className='text-center' style={{color: "#4437B8"}}> <strong>A SPECIAL TALK</strong> </h2>
        <h3 className='text-center' style={{color: "#4437B8"}}> <strong>ON</strong> </h3>
        <h1 className='text-center' style={{color: "#4437B8"}}> <strong>APPLICATION OF NEXT GENERATION IOT AND AI SYSTEM</strong> </h1>
        <h3 className='text-center' style={{color: "#4437B8"}}> <strong>DR. TED SELKER</strong> </h3>

        <div className="lightbox my-5" data-mdb-lightbox-init>
            <div className="row">
                <div className="col-lg-6">
                <img
                    src={talk1}
                    data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Slides/1.webp"
                    alt="Table Full of Spices"
                    className="w-100 mb-2 mb-md-4 shadow-1-strong rounded"
                />
                <img
                    src={talk3}
                    data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Square/1.webp"
                    alt="Coconut with Strawberries"
                    className="w-100 shadow-1-strong rounded"
                />
                </div>
                <div className="col-lg-6">
                <img
                    src={talk2}
                    data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Vertical/1.webp"
                    alt="Dark Roast Iced Coffee"
                    className="w-100 h-100 shadow-1-strong rounded"
                />
                </div>
            </div>
        </div>
        </div>

        <div className="container">

            <h1 className='text-center' style={{color: "#4437B8"}}> <strong>INTERNATIONAL CONFERENCE ON INTELLIGENT COMPUTING AND COMMUNICATION TECHNIQUES</strong> </h1>
            <h1 className='text-center' style={{color: "#4437B8"}}> <strong>2024</strong> </h1>

        <div className="row my-5">
            <div className="col-md-4 mt-3 col-lg-4">
                <img src={icict1} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-4">
                <img src={icict2} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-4">
                <img src={icict3} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-6">
                <img src={icict4} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-6">
                <img src={icict7} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-3">
                <img src={icict6} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-3">
                <img src={icict5} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-3">
                <img src={icict8} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-3">
                <img src={icict9} className="img-fluid" alt="image" />
            </div>
        </div>
    </div>


        <div className="container">

            <h1 className='text-center' style={{color: "#4437B8"}}> <strong>ATAL FDP ON INTERNET OF THINGS</strong> </h1>
            <h1 className='text-center' style={{color: "#4437B8"}}> <strong>2023</strong> </h1>

        <div className="row my-5">
            <div className="col-md-4 mt-3 col-lg-4">
                <img src={Img1} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-4">
                <img src={Img2} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-4">
                <img src={Img3} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-6">
                <img src={Img4} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-6">
                <img src={Img5} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-3">
                <img src={Img6} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-3">
                <img src={Img7} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-3">
                <img src={Img8} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-3">
                <img src={Img9} className="img-fluid" alt="image" />
            </div>
        </div>
    </div>



    <div className="container my-5">

            <h1 className='text-center' style={{color: "#4437B8"}}> <strong>INTERNATIONAL CONFERENCE ON NETWORKS AND CRYPTOLOGY</strong> </h1>
            <h1 className='text-center' style={{color: "#4437B8"}}> <strong>2019</strong> </h1>

        <div className="row my-5">
            <div className="col-md-4 mt-3 col-lg-4">
                <img src={nc1} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-4">
                <img src={nc2} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-4">
                <img src={nc3} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-6">
                <img src={nc4} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-6">
                <img src={nc5} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-3">
                <img src={nc6} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-3">
                <img src={nc7} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-3">
                <img src={nc8} className="img-fluid" alt="image" />
            </div>
            <div className="col-md-4 mt-3 col-lg-3">
                <img src={nc9} className="img-fluid" alt="image" />
            </div>
        </div>
    </div>
    
    </div>
  )
}

export default Recentevents